<template>
    <div class="container-fluid" v-if="currentUser">
        <br />
        <h3>Llistat alumnes transport</h3>
        <h5 v-if="currentUser.isEmpresa == 0">{{  centre.nom  }}</h5>
        <h5 v-if="currentUser.isEmpresa == 1 || currentUser.isEmpresa == 2">{{  currentUser.user.name  }}</h5>
        <div class="grid minWidth">
            <Toast />
            <div v-if="currentUser.isEmpresa == 2">
                <a :href="`${baseUrl}/transport/export-municipis-centres-linies-excel?token=${accessToken}`" style="height: 80px;width: 40%;margin: auto;display: flex;align-items: center;justify-content: center;margin-top: 4vh;font-size: 18px;" class="btn btn-secondary btn-sm">Excel Municipis-Centres-Serveis</a>
            </div>

            <div class="col-12 mt-4" v-if="currentUser.isEmpresa == 1">
                <div class="mb-2" v-for="(linia) in linies" :key="linia.id">
                    <a :href="`${baseUrl}/transport/get-alumnes-from-linia/excel/${linia.id}?token=${accessToken}`">
                        <button class="btn btn-secondary btn-sm">Descarrega Excel {{linia.nom}}</button>
                    </a>

                </div>


            </div>
            <div class="col-12 mt-4" v-if="currentUser.isEmpresa == 0">
                <a :href="`${baseUrl}/transport/get-alumnes-from/excel/${centre.id}?token=${accessToken}`">
                    <button class="btn btn-secondary btn-sm">Descarrega Excel</button>
                </a>

            </div>

            <div class="col-12 mt-4" v-if="currentUser.isEmpresa == 0">
                <DataTable :value="alumnes" :paginator="true" class="p-datatable-sm p-datatable-gridlines"
                    selectionMode="single" :rows="100" dataKey="id" :rowHover="true" v-model:selection="alumneSelected"
                    v-model:filters="alumneFiltre" :loading="loading" filterDisplay="row">
                    <template #header> Alumnes </template>
                    <template #empty> <span style="color:red;">No hi ha registres (carregant...)</span> </template>
                    <template #loading> Carregant... </template>

                    <Column field="ct" header="CT" :sortable="true" headerStyle="width: 80px; font-size:12px;"
                        style=" font-size:12px;" showFilterMenu="false" showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.ct  }} </template>
                    </Column>
                    <Column field="nom" header="Nom" :sortable="true" headerStyle="font-size:12px;"
                        style="font-size:12px;" :showFilterMenu="false" showClearButton="false" showApplyButton="false">
                        <template #body="slotProps">{{  slotProps.data.nom  }} </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keyup="filterCallback()"
                                class="p-column-filter" placeholder="Cerca" />
                        </template>
                    </Column>
                    <Column field="primer_cognom" header="Primer Cognom" :sortable="true" headerStyle="font-size:12px;"
                        style="font-size:12px;" :showFilterMenu="false" showClearButton="false" showApplyButton="false">
                        <template #body="slotProps">{{  slotProps.data.primer_cognom  }} </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keyup="filterCallback()"
                                class="p-column-filter" placeholder="Cerca" />
                        </template>
                    </Column>
                    <Column field="segon_cognom" header="Segon Cognom" :sortable="true" headerStyle="font-size:12px;"
                        style="font-size:12px;" :showFilterMenu="false" showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.segon_cognom  }} </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keyup="filterCallback()"
                                class="p-column-filter" placeholder="Cerca" />
                        </template>
                    </Column>
                    <Column field="poblacio" header="Població estudiant" :sortable="true" headerStyle="font-size:12px;"
                        style="font-size:12px;" :showFilterMenu="false" showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.poblacio  }} </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keyup="filterCallback()"
                                class="p-column-filter" placeholder="Cerca" />
                        </template>
                    </Column>

                    <Column field="curs" header="Curs" :sortable="true" headerStyle="font-size:12px;"
                        style="font-size:12px;" :showFilterMenu="false" showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.curs  }} </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keyup="filterCallback()"
                                class="p-column-filter" placeholder="Cerca" />
                        </template>
                    </Column>
                    <Column field="linia_anada.nom" header="Linia Anada" :sortable="true" headerStyle="font-size:12px;"
                        style="font-size:12px;" :showFilterMenu="false" showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.linia_anada?.nom  }}</template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Cerca" @keyup="filterCallback()" />
                        </template>
                    </Column>
                    <Column field="linia_tornada.nom" header="Línia Tornada" :sortable="true"
                        headerStyle="font-size:12px;" style="font-size:12px;" :showFilterMenu="false"
                        showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.linia_tornada?.nom  }}</template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Cerca" @keyup="filterCallback()" />
                        </template>
                    </Column>
                    <Column field="parada_anada.nom" header="Parada anada" :sortable="true"
                        headerStyle="font-size:12px; width: 250px;" style="font-size:12px;" :showFilterMenu="false"
                        showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.parada_anada?.nom  }} </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Cerca" @keyup="filterCallback()" />
                        </template>
                    </Column>
                    <Column field="parada_tornada.nom" header="Parada tornada" :sortable="true"
                        headerStyle="font-size:12px; width: 250px;" style="font-size:12px;" :showFilterMenu="false"
                        showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.parada_tornada?.nom  }} </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Cerca" @keyup="filterCallback()" />
                        </template>
                    </Column>
                    <Column field="linia2_anada.nom" header="Linia2 Anada" :sortable="true"
                        headerStyle="font-size:12px;" style="font-size:12px;" :showFilterMenu="false"
                        showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.linia2_anada?.nom  }}</template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Cerca" @keyup="filterCallback()" />
                        </template>
                    </Column>
                    <Column field="linia2_tornada.nom" header="Linia2 Tornada" :sortable="true"
                        headerStyle="font-size:12px;" style="font-size:12px;" :showFilterMenu="false"
                        showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.linia2_tornada?.nom  }}</template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Cerca" @keyup="filterCallback()" />
                        </template>
                    </Column>
                    <Column field="parada2_anada.nom" header="Parada2 anada" :sortable="true"
                        headerStyle="font-size:12px;" style="font-size:12px;" :showFilterMenu="false"
                        showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.parada2_anada?.nom  }} </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Cerca" @keyup="filterCallback()" />
                        </template>
                    </Column>
                    <Column field="parada2_tornada.nom" header="Parada2 tornada" :sortable="true"
                        headerStyle="font-size:12px;" style="font-size:12px;" :showFilterMenu="false"
                        showClearButton="false" showApplyButton="false">
                        <template #body="slotProps"> {{  slotProps.data.parada2_tornada?.nom  }} </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Cerca" @keyup="filterCallback()" />
                        </template>
                    </Column>
                </DataTable>
            </div>

        </div>
    </div>


</template>

<script>
import InformeService from "@/services/informes.service.js";
import TransportService from '@/services/TransportService';
import { FilterMatchMode } from 'primevue/api';
export default {
    name: "User",

    data() {
        return {
            alumnes: [],
            accessToken: null,
            baseUrl: process.env.VUE_APP_API_URL,
            linies: [],
            content: "",
            showNouInforme: false,
            centre: { id: 0, nom: "Carregant", preuMenjador: 0, preuBeca: 0 },

            alumneFiltre: {
                nom: { value: null, matchMode: FilterMatchMode.CONTAINS },
                primer_cognom: { value: null, matchMode: FilterMatchMode.CONTAINS },
                segon_cognom: { value: null, matchMode: FilterMatchMode.CONTAINS },
                poblacio: { value: null, matchMode: FilterMatchMode.CONTAINS },
                poblacio_centre: { value: null, matchMode: FilterMatchMode.CONTAINS },
                centre: { value: null, matchMode: FilterMatchMode.CONTAINS },
                curs: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'linia_anada.nom': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'linia_tornada.nom': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'parada_anada.nom': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'parada_tornada.nom': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'linia2_anada.nom': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'linia2_tornada.nom': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'parada2_anada.nom': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'parada2_tornada.nom': { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            llistats: {

                anys: [
                    { id: 2021, nom: "2021" },
                    { id: 2022, nom: "2022" },
                ],

            },
        };
    },
    async mounted() {
        let that = this;
        let user = JSON.parse(localStorage.getItem("user"));
        if(user.access_token){
            const res = await  InformeService.checkAuth();
            console.log("data",res)
            this.accessToken = user.access_token
        }

        if (user.isEmpresa == 0) {
            InformeService.getCentre(user.user.id_centre).then(
                (response) => {
                    that.centre = response.data.centre;
                    this.getAlumnesTransport(that.centre.id);
                    //that.centre.preuBeca = float.parse(that.centre.importMenjador > 6.33 ? 6.33 : that.centre.preuMenjador;
                },
                () => { }
            );
        }
        if (user.isEmpresa == 1) {
            this.getLiniesEmpresa();
        }
        //http://intern.apiabit.ccbages.cat/transport/get-alumnes-from
    },
    methods: {
        openNouInforme: function () {
            this.nouInforme.step = 0;
            this.nouInforme.preuMenjador = this.centre.importMenjador;
            this.nouInforme.preuBeca = this.centre.importBeca;

            this.showNouInforme = true;
        },
        getAlumnesTransport(idCentre) {
            let that = this;
            this.transportService.getAlumnesFrom('centre', idCentre, true).then((result) => {
                if (result.error) {
                    alert('Error');
                } else {
                    that.alumnes = result.data.alumnes;
                }
            });
        },

        getLiniesEmpresa() {
              let that = this;
              this.transportService.getLiniesFrom('empresa', this.currentUser.user.nif, true).then((result) => {
                  if (result.error) {
                      alert('Error');
                  } else {
                      that.linies = result.data.linies;
                  }
              });
        }

    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },

    created() {
        this.transportService = new TransportService();
    }
};
</script>
<style scoped>
.td {
    margin: 0px !important;

}

table {
    font-size: 11px !important;
}

@keyframes p-progress-spinner-color {

    100%,
    0% {
        stroke: #d62d20;
    }

    40% {
        stroke: #0057e7;
    }

    66% {
        stroke: #008744;
    }

    80%,
    90% {
        stroke: #ffa700;
    }
}

.estat-0,
.estat--1 {
    color: #d62d20;
}

.estat-1 {
    color: #d66320;
}

.estat-2,
.estat-3,
.estat-4,
.estat-5 {
    color: cadetblue;
}

.floatLeft {
    float: left !important;
}

.noMargin {
    margin: 0px 2px 0px 0px;
    padding: 0px;
}

.textRetorn {
    font-size: 9px;
    font-style: italic;
    line-height: 9px;
    padding: 0px;
    margin: 0px;
}
</style>