import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class InformeService {

    saveNouInforme(nouInforme) {
        return axios.post(API_URL + '/informe', nouInforme, { headers: authHeader() });
    }

    fillNouInforme(nouInforme) {
        return axios.post(API_URL + '/fill-informe', nouInforme, { headers: authHeader() });
    }

    getInformes(idCentre) {
        return axios.post(API_URL + '/get-informes', { idCentre }, { headers: authHeader() });
    }

    getInforme(idInforme) {
        return axios.post(API_URL + '/get-informe', { idInforme }, { headers: authHeader() });
    }

    saveInforme(informe) {
        return axios.post(API_URL + '/save-informe', { informe }, { headers: authHeader() });
    }
    enviarCCB(id) {
        return axios.post(API_URL + '/enviar-ccb', { id }, { headers: authHeader() });
    }
    eliminarInforme(id) {
        return axios.post(API_URL + '/del-informe', { id }, { headers: authHeader() });
    }
    getCentre(id){
        return axios.post(API_URL + '/get-centre', { id }, { headers: authHeader() });
    }
    getInformeParsed(id){
        return axios.post(API_URL + '/process-informe', { id }, { headers: authHeader() });
    }
    updateCompactacions(nouInforme) {
        return axios.post(API_URL + 'update-compactacions-informe', nouInforme, { headers: authHeader() });
    }
    async checkAuth() {
        try {
            const res = await axios.post(API_URL + '/check-auth', {}, { headers: authHeader() });
            return res
        } catch (error) {
            if(error.response.status == 401){
                localStorage.removeItem('user')
                location.href = '/login'
            }
            return error.response
        }
    }
    uploadFile(formData)
    {
        return axios.post(API_URL + '/upload-informe', formData, {
            headers: authHeader(true)
        });
    }
}

export default new InformeService();