import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;


export default class TransportService {

    addLinia(data) {
        const options = {
            data
        };

        return axios.post(API_URL + "/transport/linia/add", options, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    addParada(data) {
        const options = {
            data
        };

        return axios.post(API_URL + "/transport/parada/add", options, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    getParades(id) {
        return axios.get(API_URL + "/transport/parades/get/" + id)
            .then(response => {
                return response.data;
            });
    }
    getAllMunicipis() {
        return axios.get(API_URL + "/transport/municipis/")
            .then(response => {
                return response.data;
            });
    }
    getEmpresesTransport() {
        return axios.get(API_URL + "/transport/empreses")
            .then(response => {
                return response.data;
            });
    }
    getAllParades() {
        return axios.get(API_URL + "/transport/parades")
            .then(response => {
                return response.data;
            });
    }
    getAllAlumnes() {
        return axios.get(API_URL + "/transport/alumnes/all")
            .then(response => {
                return response.data;
            });
    }
    getAllTipusVehicles() {
        return axios.get(API_URL + "/transport/tipus-vehicles")
            .then(response => {
                return response.data;
            });
    }
    getParadesFromLinia(id) {
        const options = {
            id
        };
        return axios.post(API_URL + "/transport/parades", options, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    getAlumnesFromLinia(id) {
        const options = {
            id
        };
        return axios.post(API_URL + "/transport/linia/get-alumnes", options, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    getAll(t,extra){
        const options = {
            t,extra
        };
        return axios.post(API_URL + "/transport/get-all", options, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    getAlumnesFrom(t,id,extra){
        const options = {
            t,id,extra
        };
        return axios.post(API_URL + "/transport/get-alumnes-from", options, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    getLiniesFrom(t,id,extra){
        const options = {
            t,id,extra
        };
        return axios.post(API_URL + "/transport/get-linies-from", options, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    getDadesAlumne(id) {
        const options = {
            id
        };
        return axios.post(API_URL + "/transport/linia/get-dades-alumne", options, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    saveNovaConfiguracio(linia,dadesForm) {
        const options = {
            linia,
            dadesForm
        };

        return axios.post(API_URL + "/transport/linia/add-configuracio", options, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    addAssocLiniaParada(linia,parada) {
        const options = {
            linia,
            parada
        };

        return axios.post(API_URL + "/transport/linia/assoc-parada", options, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    async delAssocLiniaParada(linia,parada) {
        const options = {
            linia,
            parada
        };

        return axios.post(API_URL + "/transport/linia/del-assoc-parada", options, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
}
